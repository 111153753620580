<template>
  <project-layer />
</template>
  
  <script>
import { ref } from '@vue/reactivity'
import ProjectLayer from '../projectLayer/index.vue'
export default {
  name: 'MonitoringLayer',
  components: {
    ProjectLayer
  },
  setup() {
    const isLayer = ref(1)
    return { isLayer }
  }
}
</script>
  
  <style></style>
  